import { PhoneItem } from '@api-clients/crm-api-client';

export const enum MessengerType {
  Whatsapp = 'wa',
}

export function getPhoneAsStringFromChatId(phoneOrChatId: string | PhoneItem): string {
  if (typeof phoneOrChatId === 'string') {
    if (`${phoneOrChatId}`.includes('@')) {
      return phoneOrChatId.split('@')[0];
    }

    // передали номер
    return phoneOrChatId;
  }

  return `${phoneOrChatId.code}${phoneOrChatId.phone}`.replace('+', '');
}

export function generateContactChatId(phoneOrChatId: string): string {
  if (`${phoneOrChatId}`.includes('@')) {
    // Если есть собака, то возвращаем как есть
    return phoneOrChatId;
  }
  // считаем что это номер телефона
  return `${phoneOrChatId}@c.us`.replace('+', '');
}

export function isGroupChatId(chatId: string | null): boolean {
  return chatId ? chatId.includes('@g.us') : false;
}

export function getChatIdByPhone(phone: PhoneItem, messenger: MessengerType): string {
  let chatId = phone.code.replace('+', '') + phone.phone;
  if (messenger === MessengerType.Whatsapp) {
    chatId += '@c.us';
  }

  return chatId;
}

export function isCorrectChatId(chatId: string): boolean {
  return chatId.includes('@');
}
