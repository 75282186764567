import { CrmCardItem, PhoneItem } from '@api-clients/crm-api-client';
import { getChatIdByPhone, MessengerType } from './chat-fn';

export function getCardDefaultPhoneAsString(card: CrmCardItem): string {
  const main = getCardMainPhone(card);
  return main.code.replace('+', '') + main.phone;
}

export function getCardDefaultContactChatId(card: CrmCardItem, messenger: MessengerType): string {
  return getChatIdByPhone(getCardMainPhone(card), messenger);
}

export function getCardMainPhone(card: CrmCardItem): PhoneItem {
  return card.whatsappPhone ||
    card.mobilePhones[0] ||
    // нет мобильных или не смогли определить номер
    card.phones[0];
}

export function getCardPhoneDisplayName(card: CrmCardItem): string {
  const item = getCardMainPhone(card);
  return item.code + '...' + item.phone.slice(-4);
}
